import React from 'react';
import UserSidebar from './UserSidebar';

const UserWithdrawals = () => {
    return (
        <>
            <UserSidebar />

        </>
    );
};

export default UserWithdrawals;