import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IoClose } from "react-icons/io5";
import './sidebar.css';
import SignLogo from "../../assets/images/logo-w.png";
import { IoWalletOutline } from "react-icons/io5";
import { PiHandWithdraw } from "react-icons/pi";
import { LuLayoutDashboard } from "react-icons/lu";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { IoDocumentTextOutline } from "react-icons/io5";
import { AiOutlineUser } from "react-icons/ai";
import { FiSettings } from "react-icons/fi";
import { FaBarsStaggered } from 'react-icons/fa6';
import { RiVerifiedBadgeFill } from "react-icons/ri";
import Cookies from "js-cookie";

const UserSidebar = ({ sidebarOpen, toggleSidebar }) => {
    const location = useLocation();
    const [activePath, setActivePath] = useState('');
    const [showKycLink, setShowKycLink] = useState(true);

    useEffect(() => {
        setActivePath(location.pathname);
    }, [location]);


    useEffect(() => {
        const authData = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;
        const kycStatus = authData?.user?.kycstatus;

        // Set whether to show the KYC link based on kycStatus
        setShowKycLink(kycStatus !== 2);
    }, []);

    return (
        <>
            <button
                type="button"
                className="user-menu-bars"
                onClick={toggleSidebar}
            >
                <FaBarsStaggered />
            </button>

            <nav id="user-sidebar" className={sidebarOpen ? "user-active" : ""}>
                <div className="user-custom-menu">
                    <button type="button" id="user-closeSidebar" onClick={toggleSidebar}>
                        <IoClose />
                    </button>
                </div>

                <div className="py-2">
                    <div className="my-2">
                        <Link to="/home">
                            <img className="user-sidebar-logo" src={SignLogo} alt="logo" />
                        </Link>
                    </div>

                    <ul className="user-list-unstyled user-components mb-5">
                        <li className={activePath === '/dashboard/user' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user">
                                <LuLayoutDashboard className="me-3" /> Dashboard
                            </Link>
                        </li>

                        {/* Show the KYC link based on showKycLink state */}
                        {showKycLink && (
                            <li className={activePath === '/dashboard/kyc' ? 'user-active-sidebar' : ''}>
                                <Link to="/dashboard/kyc">
                                    <RiVerifiedBadgeFill className="me-3" /> KYC
                                </Link>
                            </li>
                        )}

                        <li className={activePath === '/dashboard/user/deposit' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/deposit">
                                <MdOutlineAccountBalanceWallet className="me-3" /> Deposit
                            </Link>
                        </li>
                        <li className={activePath === '/dashboard/user/withdrawals' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/withdrawals">
                                <PiHandWithdraw className="me-3" /> Withdrawals
                            </Link>
                        </li>
                        <li className={activePath === '/dashboard/user/fees' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/fees">
                                <IoWalletOutline className="me-3" /> Fees
                            </Link>
                        </li>
                        <li className={activePath === '/dashboard/user/reports' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/reports">
                                <IoDocumentTextOutline className="me-3" /> Reports
                            </Link>
                        </li>
                        <li className={activePath === '/dashboard/user/profile' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/profile">
                                <AiOutlineUser className="me-3" /> Profile
                            </Link>
                        </li>
                        <li className={activePath === '/dashboard/user/settings' ? 'user-active-sidebar' : ''}>
                            <Link to="/dashboard/user/settings">
                                <FiSettings className="me-3" /> Settings
                            </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </>
    );
};

export default UserSidebar;
