import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

const KycAll = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [allKycs, setAllKycs] = useState([]);
    const [filteredKycs, setFilteredKycs] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [loading, setLoading] = useState(false);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    // Fetch KYC data
    useEffect(() => {
        const fetchKycsData = async () => {
            setLoading(true);
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/kyc/kycs`);
                if (data?.success) {
                    const sortedKycs = data.kycs.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setAllKycs(sortedKycs);
                    setFilteredKycs(sortedKycs);
                } else {
                    toast.error(data.message);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchKycsData();
    }, []);

    // Handle Search
    useEffect(() => {
        const searchQuery = searchTerm.trim().toLowerCase();
        const filtered = searchQuery
            ? allKycs.filter(kyc => {
                const userName = `${kyc.firstName} ${kyc.lastName}`.toLowerCase();
                const userEmail = kyc.userEmail?.toLowerCase() || '';
                const userAddress = kyc.address?.toLowerCase() || '';
                const userNationality = kyc.nationality?.toLowerCase() || '';
                const kycDate = new Date(kyc.createdAt).toLocaleString().toLowerCase();

                return (
                    userName.includes(searchQuery) ||
                    userEmail.includes(searchQuery) ||
                    userAddress.includes(searchQuery) ||
                    userNationality.includes(searchQuery) ||
                    kycDate.includes(searchQuery)
                );
            })
            : allKycs;

        setFilteredKycs(filtered);
    }, [searchTerm, allKycs]);

    return (
        <div className="wrapper d-flex align-items-stretch">
            {loading && <Loader />}
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div id="content" className="px-2 pt-5">
                <button type="button" id="openSidebar" onClick={toggleSidebar} className="bars-btn">
                    <FaBarsStaggered />
                </button>
                <h3 className="b-clr">
                    Pending KYC Approvals
                </h3>
                <div className="search-add-main">
                    <div className="search-main">
                        <input
                            type="text"
                            className="search-inp"
                            placeholder="Search by name, email, address, nationality, or date..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="search-icon">
                            <IoSearchOutline />
                        </div>
                    </div>
                </div>

                <div className="tbl-main">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>NAME</th>
                                <th>EMAIL</th>
                                <th>ADDRESS</th>
                                <th>COUNTRY</th>
                                <th>DATE</th>
                                <th>ACTIONS</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredKycs.length > 0 ? (
                                filteredKycs.map(kyc => (
                                    <tr key={kyc._id}>
                                        <td>{`${kyc.firstName} ${kyc.lastName}`}</td>
                                        <td>{kyc.userEmail}</td>
                                        <td>{kyc.address}</td>
                                        <td>{kyc.nationality}</td>
                                        <td>{new Date(kyc.createdAt).toLocaleString()}</td>
                                        <td>
                                            <Link className='btn btn-primary' to={`/dashboard/admin/kyc-details/${kyc._id}`}>Details</Link>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="6">No KYC records found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default KycAll;
