import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from "react-icons/fa6";
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import { IoSearchOutline } from "react-icons/io5";


const Deposit = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredUsers, setFilteredUsers] = useState([]);
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    useEffect(() => {
        const token = Cookies.get('token');
        const authData = Cookies.get('auth');
        if (!token || !authData) {
            navigate('/login');
            return;
        }

        try {
            const role = JSON.parse(authData)?.user?.role;
            if (role === 1) return;
            if (role === 0) navigate('/dashboard/user');
            else navigate('/login');
        } catch {
            navigate('/login');
        }
    }, [navigate]);

    useEffect(() => {
        const fetchUsersData = async () => {
            try {
                const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/auth/m-users`);
                if (data?.success) {
                    setUsers(data?.users);
                    setFilteredUsers(data?.users);
                }
            } catch (error) {
                console.log(error);
            }
        };

        fetchUsersData();
    }, []);

    useEffect(() => {
        setFilteredUsers(
            searchTerm.trim()
                ? users.filter(user =>
                    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase())
                )
                : users
        );
    }, [searchTerm, users]);




    return (
        <div className="wrapper d-flex align-items-stretch">
            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div id="content" className="px-2 pt-5">
                <button type="button" id="openSidebar" onClick={toggleSidebar} className="bars-btn">
                    <FaBarsStaggered />
                </button>

                <div className="search-add-main">
                    <div className="search-main">
                        <input
                            type="text"
                            className="search-inp"
                            placeholder="Search users..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="search-icon">
                            <IoSearchOutline />
                        </div>
                    </div>
                </div>

                <div className="tbl-main">
                    <table className="simple-table">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Email</th>
                                <th>Balance</th>
                                <th>Deposit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredUsers.length > 0 ? (
                                filteredUsers.map(user => (
                                    <tr key={user._id}>
                                        <td>{user.name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.balance}</td>
                                        <td>
                                            <Link to={`/dashboard/admin/deposit-money/${user._id}`}
                                                className="btn btn-primary"

                                            >
                                                Deposit
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5">No users found</td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    );
};

export default Deposit;
