import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import UserSidebar from './UserSidebar';

import { toast } from 'react-toastify';

import { BiDollar } from "react-icons/bi";
import { LuGift } from "react-icons/lu";
import { RiArrowGoForwardFill, RiBarChart2Fill } from 'react-icons/ri';
import { FaBtc } from 'react-icons/fa6';

import { Bar, Line } from 'react-chartjs-2';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import UserNav from './UserNav';

// Register components of Chart.js
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, BarElement, Title, Tooltip, Legend);

const UserDashboard = ({ children }) => {

    const navigate = useNavigate();
    useEffect(() => {
        const token = Cookies.get('token');
        const authData = Cookies.get('auth');

        if (!token || !authData) {
            navigate('/login');
            return;
        }
        try {

            const authDataObj = JSON.parse(authData);
            if (authDataObj.user && authDataObj.user.kycStatus === 0) {
                navigate("/dashboard/kyc");
                return;
            } else if (authDataObj.user && authDataObj.user.kycStatus === 1) {
                navigate("/dashboard/kyc");
                return;
            } else if (authDataObj.user && authDataObj.user.kycStatus === 2) {
                navigate("/dashboard/profile");
                return;

            }
        } catch (error) {
            console.error('Error parsing authData:', error);
            navigate('/login');
        }
    }, [navigate]);

    const [sidebarOpen, setSidebarOpen] = useState(false);

    // Function to toggle the sidebar open/close
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };





    const data = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Dataset 1',
                data: [65, 59, 80, 81, 56, 55, 40],
                borderColor: '#00349A', // Set line color
                backgroundColor: 'transparent',
                fill: false,
                tension: 0.1,
            },
            {
                label: 'Dataset 2',
                data: [28, 48, 40, 19, 86, 27, 90],
                borderColor: '#00349A', // Set line color
                backgroundColor: 'transparent',
                fill: false,
                tension: 0.1,
            },
        ],
    };

    // Chart options
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: true,
                position: 'top',
            },
            title: {
                display: true,
                text: 'Double Line Chart Example',
            },
        },
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Months',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Values',
                },
            },
        },
    };

    // Bar chart data
    const labels = ['January', 'February', 'March', 'April', 'May', 'June'];

    const barData = {
        labels,
        datasets: [
            {
                label: 'Dataset 3 (Bar)',
                data: [45, 67, 54, 33, 92, 78],
                backgroundColor: '#00349A', // Bar background color
                borderColor: '#00349A', // Bar border color
                borderWidth: 1,
            },
        ],
    };
    const Transactions = [
        {
            id: 1,
            date: '2024-10-20',
            amount: 500,
            debit: 500,
            credit: 0,
            type: 'Withdrawal'
        },
        {
            id: 2,
            date: '2024-10-21',
            amount: 1000,
            debit: 0,
            credit: 1000,
            type: 'Deposit'
        },
        {
            id: 3,
            date: '2024-10-22',
            amount: 750,
            debit: 750,
            credit: 0,
            type: 'Purchase'
        }
    ];
    return (
        <>
            <div className="dashboard-container"></div>
            <UserSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Main Content */}
            <div className={`dashboard-main ${sidebarOpen ? 'shifted' : ''}`}>
                <UserNav title={"Dashboard"} />
                <div className="dashboard-content">
                    <div className="user-cards-main">

                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <RiBarChart2Fill />
                                </div>
                                <div className="dashboard-user-card-text">NAV</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2">Daily Text will he here</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <BiDollar />
                                </div>
                                <div className="dashboard-user-card-text">Deposits</div>
                            </div>
                            <div className="dashboard-user-card-text text-center pt-2">Fiat $340</div>
                            <div className="dashboard-user-card-text text-center">Crypto $340</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <LuGift />
                                </div>
                                <div className="dashboard-user-card-text">Redemptions</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2"> $340</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <RiArrowGoForwardFill />
                                </div>
                                <div className="dashboard-user-card-text">Returns</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2"> $230</div>

                        </div>
                        <div className="dashboard-user-card">
                            <div className="dashboard-user-card-icon-main">
                                <div className="dashboard-user-card-icon">
                                    <FaBtc />
                                </div>
                                <div className="dashboard-user-card-text">Active Balance</div>
                            </div>
                            <div className="dashboard-user-card-text text-center py-2"> 30</div>

                        </div>
                    </div>

                    <div className="container-fluid my-3">
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <div className='line-chart'>
                                    <Line data={data} options={options} />
                                </div>

                            </div>
                            <div className="col-md-6 mb-3">
                                <div className="bar-chart">
                                    <Bar data={barData} options={options} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="recent-activity-main">
                        <h3 className='recent-title'>Recent Activities</h3>
                        <div className="recent-activities-sub">
                            <div className="tbl-main">
                                <div className="tbl-main">
                                    <table className="simple-table">
                                        <thead>
                                            <tr>

                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Debit</th>
                                                <th>Credit</th>
                                                <th>Type</th>

                                            </tr>
                                        </thead>
                                        <tbody>

                                            {Transactions.map((transaction) => (
                                                <tr key={transaction.id}>
                                                    <td>{transaction.date}</td>
                                                    <td>${transaction.amount}</td>
                                                    <td>{transaction.debit ? `$${transaction.debit}` : '-'}</td>
                                                    <td>{transaction.credit ? `$${transaction.credit}` : '-'}</td>
                                                    <td>{transaction.type}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>



                                </div>
                            </div>
                        </div>
                    </div>

                </div>


            </div>
        </>
    );
};

export default UserDashboard;



