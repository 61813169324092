// UtilityBillUploadModal.js
import React, { useState } from 'react';
import { Modal } from 'antd';
import { IoCloudUploadOutline } from 'react-icons/io5';

const UtilityBillUploadModal = ({ visible, onClose, onFileChange, onUpload, selectedFile }) => {
    const [imagePreview, setImagePreview] = useState(null);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            onFileChange(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <Modal open={visible} footer={null} onCancel={onClose}>
            <div className="upload-dropzone" onClick={() => document.getElementById('utilityBillFileInput').click()}>
                <IoCloudUploadOutline style={{ fontSize: '2em', color: '#ccc' }} />
                <p>Upload Utility Bill</p>
                <input
                    type="file"
                    id="utilityBillFileInput"
                    accept=".jpg, .png, .pdf"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
            </div>
            <p>JPG, PNG, or PDF, up to 5 MB</p>
            {imagePreview && <img src={imagePreview} alt="Utility Bill Preview" style={{ width: '100px', height: '100px' }} />}
            <div className="upload-actions">
                <button className="upload-btn" onClick={onClose}>Upload</button>
                <button className="cancel-btn" onClick={onClose}>Cancel</button>
            </div>
        </Modal>
    );
};

export default UtilityBillUploadModal;
