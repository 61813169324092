import React from 'react';
import Fees from './Fees';

const Reports = () => {
    return (
        <>
            <Fees />
        </>
    );
};

export default Reports;