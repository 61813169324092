import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Sidebar from './Sidebar';
import { FaBarsStaggered } from "react-icons/fa6";
import { LuUser2 } from "react-icons/lu";
import { toast } from "react-toastify";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { PiHandWithdraw } from "react-icons/pi";
import { GiWallet } from "react-icons/gi";
import axios from 'axios';
import { FaAward } from "react-icons/fa6";
import { VscGraph } from "react-icons/vsc";
import { LuUserCog } from "react-icons/lu";
import { LiaUsersSolid } from "react-icons/lia";
import { MdOutlinePendingActions } from "react-icons/md";
import { RiDeviceRecoverLine } from "react-icons/ri";
import { IoSearchOutline } from "react-icons/io5";
import { ImUnlocked } from "react-icons/im";
import { FaLock } from "react-icons/fa";
import { HiChevronDown } from "react-icons/hi2";
import AdminNav from './AdminNav';
import Loader from '../../components/Loader';
const AdminDash = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [users, setUsers] = useState([]);  // List of users from the server
    const [searchTerm, setSearchTerm] = useState("");  // Search term state
    const [filteredUsers, setFilteredUsers] = useState([]);  // Filtered users after search
    const [loading, setLoading] = useState(false);
    const [expandedRow, setExpandedRow] = useState(null); // State to track expanded row

    // Toggle row expansion
    const toggleRow = (userId) => {
        if (expandedRow === userId) {
            setExpandedRow(null); // Collapse if the row is already expanded
        } else {
            setExpandedRow(userId); // Expand the clicked row
        }
    };

    // Toggle sidebar
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };
    // Check user authentication and role
    useEffect(() => {
        const token = Cookies.get('token');
        const authData = Cookies.get('auth');

        if (!token || !authData) {
            navigate('/login');
            return;
        }

        try {
            const parsedAuthData = JSON.parse(authData);
            const role = parsedAuthData?.user?.role;

            if (role === 1) return;
            if (role === 0) navigate('/dashboard/user');
            else navigate('/login');
        } catch (error) {
            navigate('/login');
        }
    }, [navigate]);
    // Fetch all users data from the server
    const getAllUsersData = async () => {
        try {
            const { data } = await axios.get(`${process.env.REACT_APP_API}/api/v1/auth/m-users`);
            if (data?.success) {
                setUsers(data?.users);
                setFilteredUsers(data?.users);  // Initialize filtered users with all users
            }
        } catch (error) {
            console.log(error);
        }
    };
    // Fetch users when component mounts
    useEffect(() => {
        getAllUsersData();
    }, []);
    // Filter users based on search term
    useEffect(() => {
        if (searchTerm.trim() === "") {
            setFilteredUsers(users);  // Reset to all users when search term is cleared
        } else {
            const filteredData = users.filter(user =>
                user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                user.email.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setFilteredUsers(filteredData);
        }
    }, [searchTerm, users]);


    const getKYCStatus = (kycstatus) => {
        switch (kycstatus) {
            case 0: return 'Not Submitted';
            case 1: return 'Pending Approval';
            case 2: return 'Verified';
            case 3: return 'Rejected';
            default: return 'Unknown';
        }
    };

    const getKYCClass = (kycstatus) => {
        switch (kycstatus) {
            case 0: return 'kyc-not-submitted';
            case 1: return 'kyc-pending';
            case 2: return 'kyc-verified';
            case 3: return 'kyc-rejected';
            default: return '';
        }
    };

    const getUserStatus = (status) => {
        switch (status) {
            case 0: return <ImUnlocked />;
            case 1: return <FaLock />;
            default: return 'Unknown';
        }
    };

    const getUserStatusClass = (status) => {
        switch (status) {
            case 0: return 'status-active';
            case 1: return 'status-locked';
            case 2: return 'status-temp-locked';
            default: return '';
        }
    };


    return (
        <div className="wrapper d-flex align-items-stretch">
            {loading && <Loader />}

            <Sidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Page Content */}
            <div id="content" className="px-2">
                <button type="button" id="openSidebar" onClick={toggleSidebar} className="bars-btn">
                    <FaBarsStaggered />
                </button>
                <AdminNav />
                <div className="mt-3">
                    <div className="boxes-main">
                        <Link className='box-btn'>
                            <LuUser2 className='box-btn-icon' />
                            <h4 className="box-title">

                                {users.length}
                            </h4>
                            <p className="box-text">
                                Active Users
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <FaHandHoldingDollar className='box-btn-icon' />
                            <h4 className="box-title">

                                $288
                            </h4>
                            <p className="box-text">
                                Total Deposit
                            </p>
                        </Link>

                        <Link className='box-btn'>
                            <PiHandWithdraw className='box-btn-icon' />
                            <h4 className="box-title">
                                $5087
                            </h4>
                            <p className="box-text">
                                Total Withdrawals
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <GiWallet className='box-btn-icon' />
                            <h4 className="box-title">
                                $244.00
                            </h4>
                            <p className="box-text">
                                Current Balance
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <FaAward className='box-btn-icon' />
                            <h4 className="box-title">
                                44
                            </h4>
                            <p className="box-text">
                                Total Rewards
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <VscGraph className='box-btn-icon' />
                            <h4 className="box-title">
                                $85
                            </h4>
                            <p className="box-text">
                                Investment Deployed
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <LuUserCog className='box-btn-icon' />
                            <h4 className="box-title">
                                60
                            </h4>
                            <p className="box-text">
                                Active Fields
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <LiaUsersSolid className='box-btn-icon' />
                            <h4 className="box-title">
                                8
                            </h4>
                            <p className="box-text">
                                Total Referrals
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <MdOutlinePendingActions className='box-btn-icon' />
                            <h4 className="box-title">
                                $356
                            </h4>
                            <p className="box-text">
                                Pending Deposits
                            </p>
                        </Link>
                        <Link className='box-btn'>
                            <RiDeviceRecoverLine className='box-btn-icon' />
                            <h4 className="box-title">
                                110
                            </h4>
                            <p className="box-text">
                                Total Redemptions
                            </p>
                        </Link>
                    </div>
                </div>
                <div className="search-add-main">
                    <div className="search-main">
                        <input
                            type="text"
                            className='search-inp'
                            placeholder='Search users...'
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <div className="search-icon">
                            <IoSearchOutline />
                        </div>
                    </div>

                </div>

                <div className="tbl-main">
                    <div className="tbl-main">
                        <table className="simple-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>User</th>
                                    <th>KYC Status</th>
                                    <th>Balance</th>
                                    <th>Deposits</th>
                                    <th>Withdrawals</th>
                                    <th>Profit</th>
                                    <th>Fees</th>
                                    <th>Status</th>
                                    <th>Edit</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredUsers.length > 0 ? (
                                    filteredUsers.map((user) => (
                                        <tr key={user._id}>
                                            {/* Chevron icon cell */}
                                            <td className="chevron-cell" onClick={() => toggleRow(user._id)}>
                                                <HiChevronDown
                                                    className={`chevron-icon ${expandedRow === user._id ? 'rotated' : ''}`}
                                                />
                                            </td>

                                            {/* User data cell */}
                                            <td>
                                                <span className="username">{user.name}</span>

                                                {/* Email cell, toggle visibility */}
                                                <p className={`email ${expandedRow === user._id ? 'show' : ''}`}>
                                                    {user.email}
                                                </p>
                                            </td>

                                            {/* Other table cells */}
                                            <td>
                                                <span className={`kyc-status ${getKYCClass(user.kycstatus)}`}>
                                                    {getKYCStatus(user.kycstatus)}
                                                </span>
                                            </td>
                                            <td>{user.balance}</td>
                                            <td>$ 4637</td>
                                            <td>$ 2345</td>
                                            <td>$ 456</td>
                                            <td>$ 89</td>
                                            <td>
                                                <span className={`user-status ${getUserStatusClass(user.status)}`}>
                                                    {getUserStatus(user.status)}
                                                </span>
                                            </td>

                                            <td>
                                                <Link to={`/dashboard/admin/edit-user/${user._id}`} className="btn btn-primary mx-2">Edit</Link>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="10">No users found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default AdminDash;
