import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import UserSidebar from './UserSidebar';
import { toast } from 'react-toastify';
import UserNav from './UserNav';
import Share from "../../assets/images/share.svg";
import NationalId from "../../assets/images/national-id.svg";
import Passport from "../../assets/images/passport.svg";
import { Steps } from 'antd';
import axios from 'axios';
import NationalIdUploadModal from './modals/NationalIdUploadModal';
import PassportUploadModal from './modals/PassportUploadModal';
import ProofOfResidenceUploadModal from './modals/ProofOfResidenceUploadModal';
import BankStatementUploadModal from './modals/BankStatementUploadModal';
import UtilityBillUploadModal from './modals/UtilityBillUploadModal';
import LocalAuthorityStatementUploadModal from './modals/LocalAuthorityStatementUploadModal';
import Loader from '../../components/Loader';
import { IoCheckmark } from 'react-icons/io5';

const { Step } = Steps;

const Kyc = () => {

    const authData = Cookies.get('auth');
    const authDataString = JSON.parse(authData);

    const { email, _id } = authDataString.user;

    const navigate = useNavigate();
    const [isNationalIdModalVisible, setNationalIdModalVisible] = useState(false);
    const [isPassportModalVisible, setPassportModalVisible] = useState(false);
    const [isProofOfResidenceModalVisible, setProofOfResidenceModalVisible] = useState(false);
    const [isBankStatementModalVisible, setBankStatementModalVisible] = useState(false);
    const [isUtilityBillModalVisible, setUtilityBillModalVisible] = useState(false);
    const [isLocalAuthorityStatementModalVisible, setLocalAuthorityStatementModalVisible] = useState(false);

    const [nationalIdFile, setNationalIdFile] = useState(null);
    const [passportFile, setPassportFile] = useState(null);
    const [proofOfResidenceFile, setProofOfResidenceFile] = useState(null);
    const [bankStatementFile, setBankStatementFile] = useState(null);
    const [utilityBillFile, setUtilityBillFile] = useState(null);
    const [localAuthorityStatementFile, setLocalAuthorityStatementFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [final, setFinal] = useState(false);


    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentStep, setCurrentStep] = useState(0);
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        dob: '',
        gender: '',
        address: '',
        nationality: '',
        designation: '',
        identityType: '',
        verificationBy: "",
        email: email,
        id: _id,
    });
    const [errors, setErrors] = useState({}); // To store validation errors

    // Function to toggle the sidebar open/close
    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    // Handle form data changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    useEffect(() => {
        // Check if kycStatus is equal to 1 and update final state
        if (authDataString.user.kycStatus === 1) {
            setFinal(true);
        }
    }, [authDataString.user.kycStatus]);
    // Validation function
    const validateFields = (fields) => {
        const newErrors = {};
        fields.forEach((field) => {
            if (!formData[field]) newErrors[field] = "This field is required";
        });
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const DocumentPreview = ({ file, label }) => {
        const isPDF = file.type === "application/pdf";

        return (
            <div className="uploaded-doc">
                <p><strong>{label}:</strong></p>
                {isPDF ? (
                    <div className="pdf-preview">
                        <span className="pdf-icon">📄</span> PDF File Uploaded
                        <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer" className="view-link">
                            View PDF
                        </a>
                    </div>
                ) : (
                    <img
                        src={URL.createObjectURL(file)}
                        alt={label}
                        className="uploaded-image"
                    />
                )}
            </div>
        );
    };

    const handleNextStep = () => {
        let requiredFields = [];

        if (currentStep === 1) {
            requiredFields = ["identityType"];
        }
        else if (currentStep === 2) {
            requiredFields = ["firstName", "lastName", "dob", "gender", "address", "nationality", "designation"];
        }
        else if (currentStep === 3) {
            // Check if either National ID or Passport image preview is available
            if (!nationalIdFile && !passportFile) {
                toast.error("Please upload an ID Card or Passport image.");
                return;
            }
        }
        else if (currentStep === 4) {
            if (!proofOfResidenceFile && !bankStatementFile && !utilityBillFile && !localAuthorityStatementFile) {
                toast.error("Please upload at least one residency document.");
                return;
            }
        }

        // If validation passes, move to the next step
        if (validateFields(requiredFields)) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const handlePrevStep = () => setCurrentStep((prev) => prev - 1);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const data = new FormData();

        // Append each field individually instead of as a JSON blob
        data.append('id', formData.id);
        data.append('email', formData.email);
        data.append('firstName', formData.firstName);
        data.append('lastName', formData.lastName);
        data.append('dob', formData.dob);
        data.append('gender', formData.gender);
        data.append('address', formData.address);
        data.append('nationality', formData.nationality);
        data.append('designation', formData.designation);
        data.append('identityType', formData.identityType);
        data.append('verificationBy', formData.verificationBy);

        // Append files conditionally
        if (nationalIdFile) data.append('nationalId', nationalIdFile);
        if (passportFile) data.append('passport', passportFile);
        if (proofOfResidenceFile) data.append('proofOfResidence', proofOfResidenceFile);
        if (bankStatementFile) data.append('bankStatement', bankStatementFile);
        if (utilityBillFile) data.append('utilityBill', utilityBillFile);
        if (localAuthorityStatementFile) data.append('localAuthority', localAuthorityStatementFile);

        try {
            const res = await axios.post(`${process.env.REACT_APP_API}/api/v1/kyc/new-kyc`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            if (res.data.success) {
                toast.success(res.data.message);
                setFinal(true);
                authDataString.user.kycStatus = 1;

                // Step 3: Set the updated auth data back to the cookie
                Cookies.set('auth', JSON.stringify(authDataString), { expires: 1 });

            } else {
                toast.error(res.data.message);
            }

        } catch (error) {
            console.error(error);
            toast.error("Failed to submit KYC. Try again.");
        } finally {
            setLoading(false); // Hide loader
        }
    };

    // Rendering different step components based on current step
    const renderStepContent = () => {
        switch (currentStep) {
            case 0:
                return (
                    <div className="kyc-screen common-prop">
                        <h1 className="kyc-title mb-4">KYC Verification</h1>
                        <div className="kyc-text w-50 mb-5">
                            To ensure the security of your account and comply with regulatory requirements, we need to verify your identity. <span>This will only take 5 minutes!</span>
                        </div>
                        <button className='common-kyc-button' onClick={handleNextStep}>Get Started</button>
                    </div>
                );
            case 1:
                return (
                    <div className="kyc-screen common-prop">
                        <h1 className="kyc-title mb-4">Determine Your Identity</h1>
                        <div className="kyc-text w-50 mb-5">
                            Please select one from the following options to process your application
                        </div>
                        <div className="select-main">
                            <div className="inp-card-radio">
                                <div className="d-flex align-items-center">
                                    <span className='span-text ms-3'>Individual</span>
                                </div>
                                <input type="radio" name="identityType" value="individual" onChange={handleInputChange} />
                            </div>
                            <div className="inp-card-radio">
                                <div className="d-flex align-items-center">
                                    <span className='span-text ms-3'>Company</span>
                                </div>
                                <input type="radio" name="identityType" value="company" onChange={handleInputChange} />
                            </div>
                        </div>
                        {errors.identityType && <p className="error-text">{errors.identityType}</p>}

                        <button className='common-kyc-button' onClick={handleNextStep}>Continue</button>
                    </div>
                );
            case 2:
                return (
                    <div className="kyc-screen common-prop">
                        <h1 className="kyc-title my-4">Verify Your Identity</h1>
                        <div className="mb-5">
                            <Steps current={0}>
                                <Step title="Personal Info" />
                                <Step title="Identity Verification" />
                                <Step title="Proof of Residence" />
                                <Step title="Completed" />
                            </Steps>
                        </div>
                        <div className="personal-info">
                            <div className="personal-info-card">
                                <div className="per-info-card">
                                    <label>First Name</label>
                                    <input type="text" className='personal-inp' name="firstName" placeholder='First Name' value={formData.firstName} onChange={handleInputChange} />
                                    {errors.firstName && <p className="error-text">{errors.firstName}</p>}
                                </div>
                                <div className="per-info-card">
                                    <label>Last Name</label>
                                    <input type="text" className='personal-inp' name="lastName" placeholder='Last Name' value={formData.lastName} onChange={handleInputChange} />
                                    {errors.lastName && <p className="error-text">{errors.lastName}</p>}
                                </div>
                                <div className="per-info-card">
                                    <label>Date of Birth</label>
                                    <input type="date" className='personal-inp' name="dob" placeholder='Date of Birth' value={formData.dob} onChange={handleInputChange} />
                                    {errors.dob && <p className="error-text">{errors.dob}</p>}
                                </div>
                                <div className="per-info-card">
                                    <label>Gender</label>
                                    <select name="gender" className='personal-inp' value={formData.gender} onChange={handleInputChange}>
                                        <option value="" disabled>Select</option>
                                        <option value="male">Male</option>
                                        <option value="female">Female</option>
                                    </select>
                                    {errors.gender && <p className="error-text">{errors.gender}</p>}
                                </div>
                                <div className="per-info-card w-100">
                                    <label>Home Address</label>
                                    <input type="text" className='personal-inp' name="address" placeholder='Home Address' value={formData.address} onChange={handleInputChange} />
                                    {errors.address && <p className="error-text">{errors.address}</p>}
                                </div>
                                <div className="per-info-card">
                                    <label>Nationality</label>
                                    <select name="nationality" className='personal-inp' value={formData.nationality} onChange={handleInputChange}>
                                        <option value="" disabled>Select</option>
                                        <option value="United States">United States</option>
                                        <option value="Germany">Germany</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="Canada">Canada</option>
                                    </select>
                                    {errors.nationality && <p className="error-text">{errors.nationality}</p>}
                                </div>
                                <div className="per-info-card">
                                    <label>Designation</label>
                                    <select name="designation" className='personal-inp' value={formData.designation} onChange={handleInputChange}>
                                        <option value="" disabled>Select</option>
                                        <option value="Mr">Mr</option>
                                        <option value="Mrs">Mrs</option>
                                        <option value="Ms">Ms</option>
                                    </select>

                                    {errors.designation && <p className="error-text">{errors.designation}</p>}
                                </div>
                            </div>
                            <div className="d-flex justify-content-between my-3">

                                <button className="common-kyc-button" onClick={handlePrevStep}>Back</button>

                                <button className='common-kyc-button' onClick={handleNextStep}>Continue</button>
                            </div>
                        </div>
                    </div>
                );
            case 3:

                return (
                    <div className="kyc-screen common-prop">
                        <h1 className="kyc-title my-4">Verify Your Identity</h1>
                        <div className="mb-5">
                            <Steps current={1}>
                                <Step title="Personal Info" />
                                <Step title="Identity Verification" />
                                <Step title="Proof of Residence" />
                                <Step title="Completed" />
                            </Steps>
                        </div>
                        <p className="kyc-text my-3">
                            Select any one to start the verification
                        </p>
                        <div className="select-main d-flex gap-4">
                            <div className="inp-card-radio">
                                <div className="d-flex align-items-center">
                                    <img src={NationalId} alt="Individual" />
                                    <span className="span-text ms-3">National Identification Card</span>
                                </div>
                                <input
                                    type="radio"
                                    name="verificationBy"
                                    value="NationalID"
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className="inp-card-radio">
                                <div className="d-flex align-items-center">
                                    <img src={Passport} alt="Passport" />
                                    <span className="span-text ms-3">Passport</span>
                                </div>
                                <input
                                    type="radio"
                                    name="verificationBy"
                                    value="Passport"
                                    onChange={handleInputChange}
                                />
                            </div>
                            {errors.verificationBy && <p className="error-text">{errors.verificationBy}</p>}
                        </div>

                        <div className="upload-image-main">
                            {(nationalIdFile || passportFile) && <IoCheckmark className='tick-icon' />}

                            <div
                                className="upload-images-main-right"
                                onClick={() => formData.verificationBy === 'NationalID' ? setNationalIdModalVisible(true) : setPassportModalVisible(true)}
                            >
                                <img src={Share} alt="Upload Icon" />
                                <div className="upload-image-text-right">
                                    {formData.verificationBy === 'NationalID' ? 'ID Card Image' : 'Passport Image'} <br />
                                    <span>Upload</span>
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between my-3  ">

                            <button className="common-kyc-button" onClick={handlePrevStep}>Back</button>

                            <button className='common-kyc-button' onClick={handleNextStep}>Continue</button>
                        </div>
                    </div>
                );
            case 4:
                return (
                    <div className="kyc-screen common-prop">
                        <h1 className="kyc-title my-4">Residency Proof</h1>
                        <div className="mb-5">
                            <Steps current={2}>
                                <Step title="Personal Info" />
                                <Step title="Identity Verification" />
                                <Step title="Proof of Residence" />
                                <Step title="Completed" />
                            </Steps>
                        </div>


                        <div className="upload-image-main">
                            {(proofOfResidenceFile) && <IoCheckmark className='tick-icon' />}

                            <div className="upload-images-main-right" onClick={() => setProofOfResidenceModalVisible(true)}>
                                <img src={Share} alt="" />
                                <div className="upload-image-text-right">
                                    Proof of Residence<br />
                                    <span>Upload</span>
                                </div>
                            </div>
                        </div>

                        <div className="other-statements-main">
                            <h3 className='my-3'>
                                Other Documents
                            </h3>
                            <div className="other-statements-sub">

                                <div className="other-statement" onClick={() => setBankStatementModalVisible(true)}>
                                    {(bankStatementFile) && <IoCheckmark className='tick-icon' />}

                                    <img src={Share} alt="" />
                                    <div className="upload-image-text-right">
                                        Bank Statement<br />
                                        <span>Upload</span>
                                    </div>

                                </div>
                                <div className="other-statement" onClick={() => setUtilityBillModalVisible(true)}>
                                    {(utilityBillFile) && <IoCheckmark className='tick-icon' />}

                                    <img src={Share} alt="" />
                                    <div className="upload-image-text-right">
                                        Utility Bill<br />
                                        <span>Upload</span>
                                    </div>

                                </div>
                                <div className="other-statement" onClick={() => setLocalAuthorityStatementModalVisible(true)}>
                                    {(localAuthorityStatementFile) && <IoCheckmark className='tick-icon' />}

                                    <img src={Share} alt="" />
                                    <div className="upload-image-text-right">
                                        Local Authority Statement<br />
                                        <span>Upload</span>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="text-end my-3">
                            <button className="common-kyc-button" onClick={handlePrevStep}>Back</button>

                            <button className="common-kyc-button" onClick={handleNextStep}>Continue</button>
                        </div>
                    </div>
                );
            case 5:
                return (
                    <div className="kyc-screen common-prop">
                        <h2 className="kyc-title my-4">Summary</h2>
                        <div className="summary-main">
                            <div className="summary-sub">
                                <label>First Name </label>
                                <span>{formData.firstName}</span>
                            </div>
                            <div className="summary-sub">
                                <label>Last Name </label>
                                <span>{formData.lastName}</span>
                            </div>
                            <div className="summary-sub">
                                <label>Date of Birth </label>
                                <span>{formData.dob}</span>
                            </div>
                            <div className="summary-sub">
                                <label> Gender </label>
                                <span>{formData.gender}</span>
                            </div>
                            <div className="summary-sub">
                                <label> Address </label>
                                <span>{formData.address}</span>
                            </div>
                            <div className="summary-sub">
                                <label> Nationality </label>
                                <span>{formData.nationality}</span>
                            </div>
                            <div className="summary-sub">
                                <label> Designation </label>
                                <span>{formData.designation}</span>
                            </div>
                            <div className="summary-sub">
                                <label> Identification By </label>
                                <span>{formData.verificationBy}</span>
                            </div>
                            <div className="summary-sub">
                                <label>  Identity Type </label>
                                <span>{formData.identityType}</span>
                            </div>

                        </div>
                        <div className="w-100 text-start my-3">
                            <h3>Uploaded Documents:</h3>
                            <div className="w-100 text-start my-3">

                                {nationalIdFile && (
                                    <DocumentPreview file={nationalIdFile} label="National ID" />
                                )}

                                {passportFile && (
                                    <DocumentPreview file={passportFile} label="Passport" />
                                )}

                                {proofOfResidenceFile && (
                                    <DocumentPreview file={proofOfResidenceFile} label="Proof of Residence" />
                                )}

                                {bankStatementFile && (
                                    <DocumentPreview file={bankStatementFile} label="Bank Statement" />
                                )}

                                {utilityBillFile && (
                                    <DocumentPreview file={utilityBillFile} label="Utility Bill" />
                                )}

                                {localAuthorityStatementFile && (
                                    <DocumentPreview file={localAuthorityStatementFile} label="Local Authority Statement" />
                                )}
                            </div>


                        </div>




                        <div className="summary-btns">
                            <button className='common-kyc-button' onClick={handlePrevStep}>Back</button>
                            <button className='common-kyc-button' onClick={handleSubmit}>Confirm</button>
                        </div>
                    </div>

                );

            default:
                return null;
        }
    };


    useEffect(() => {
        const authData = Cookies.get('auth') ? JSON.parse(Cookies.get('auth')) : null;
        const kycStatus = authData?.user?.kycstatus;
        if (kycStatus === 2) {
            return navigate("/dashboard/user");

        }
    }, [navigate]);
    return (
        <>
            {loading && <Loader />}
            <div className="dashboard-container"></div>
            <UserSidebar sidebarOpen={sidebarOpen} toggleSidebar={toggleSidebar} />
            <div className={`dashboard-main ${sidebarOpen ? 'shifted' : ''}`}>
                <UserNav title={"KYC in Progress"} />
                <div className="dashboard-content">
                    {/* Only render main content if final is false */}
                    {!final && (
                        <div className="kyc-verification-start">
                            <div className="d-flex flex-column justify-content-center align-items-center">
                                {renderStepContent()}
                            </div>
                        </div>
                    )}

                    {final && (
                        <div className="kyc-screen common-prop" style={{ margin: "0px auto" }}>
                            <h1 className="kyc-title my-4">Completed</h1>
                            <div className="mb-5">
                                <Steps current={4}>
                                    <Step title="Personal Info" />
                                    <Step title="Identity Verification" />
                                    <Step title="Proof of Residence" />
                                    <Step title="Completed" />
                                </Steps>
                            </div>

                            <h2 className="my-3">Verification In Progress</h2>
                            <p className="kyc-text">
                                Once checks are all complete, we'll let you know via email. This usually takes between 3 minutes to an hour, but sometimes can take up to 24 hours.
                            </p>
                        </div>
                    )}
                </div>


            </div>
            <NationalIdUploadModal
                visible={isNationalIdModalVisible}
                onClose={() => setNationalIdModalVisible(false)}
                onFileChange={setNationalIdFile}
                selectedFile={nationalIdFile}
            />
            <PassportUploadModal
                visible={isPassportModalVisible}
                onClose={() => setPassportModalVisible(false)}
                onFileChange={setPassportFile}
                selectedFile={passportFile}
            />
            <ProofOfResidenceUploadModal
                visible={isProofOfResidenceModalVisible}
                onClose={() => setProofOfResidenceModalVisible(false)}
                onFileChange={setProofOfResidenceFile}

                selectedFile={proofOfResidenceFile}
            />
            <BankStatementUploadModal
                visible={isBankStatementModalVisible}
                onClose={() => setBankStatementModalVisible(false)}
                onFileChange={setBankStatementFile}

                selectedFile={bankStatementFile}
            />
            <UtilityBillUploadModal
                visible={isUtilityBillModalVisible}
                onClose={() => setUtilityBillModalVisible(false)}
                onFileChange={setUtilityBillFile}

                selectedFile={utilityBillFile}
            />
            <LocalAuthorityStatementUploadModal
                visible={isLocalAuthorityStatementModalVisible}
                onClose={() => setLocalAuthorityStatementModalVisible(false)}
                onFileChange={setLocalAuthorityStatementFile}

                selectedFile={localAuthorityStatementFile}
            />
        </>
    );
};

export default Kyc;
